define('fusion/ui/controls/fusion-checkbox',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log) {
        var c = this;

        c.settingsDefinition = {
            isChecked: { isLive: true },
            value: { isLive: true },
            labelText: { isLive: true },
            isEnabled: { isLive: true, defaultValue: true }
        };

        c.validateValues = function (settings) {

            if (!settings.__hasDirectContent && !settings.labelText()) {
                throw new Error("fusion-checkbox must have either content or labelText");
            }

        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {

        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {
        }

    });
});
