define('fusion/ui/controls/fusion-esrimap',[
    "fusion/fusion.control",
    "text!fusion/ui/controls/fusion-esrimap-iframecontent.html"
], function (ControlFactory, iframeControl) {
    return ControlFactory.control(function ($, require, $log, ko) {
        var c = this;

        c.settingsDefinition = {
            initCallback: { isRequired: true, isLive: false },
            esriServices: { isRequired: true, isLive: false },
            esriLayoutTemplate: { isRequired: false, isLive: false },
            esriApiVersion: {isRequired: false, isLive: false, defaultValue: '3.27'},
            esriTheme: { isRequired: false, isLive: false, defaultValue: 'light' }
            //isMapBusy: { isRequired: false, isLive: true, defaultValue: false },
            //esriMapStylesPath: { isRequired: false, isLive: false }
        };



        c.validateValues = function (settings) {

        }


        c.beforeBind = function ($markup, settings, bindingContext, $element) {
            
            // adding settings props for height and width
            settings.esriPartHeight = ko.observable('100%');
            settings.esriPartWidth = ko.observable('100%');
        }


        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }


        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

            var _numFusionToolBars = $markup.find("fusion-toolbar").length;
           

            // construct JS path
            var _arcGisBasePath = "//js.arcgis.com/" + settings.esriApiVersion + "/";
            // construct CSS path
            var _esriCssFileName = (parseFloat(settings.esriApiVersion) > 4) ? "main.css" : "esri.css";


            // acquiring the iframe HTML
            var iframeHtml = iframeControl;


            // Require esri 
            var headSection = iframeHtml.split("<!-- STYLE SEARCH KEY -->");
            headSection[0] = headSection[0] + '<script src="' + _arcGisBasePath + '"></script>';            


            // adding ESRI css
            var styleSection = "<link rel='stylesheet' href='" + _arcGisBasePath + "esri/css/"+_esriCssFileName+"'>";
            headSection[0] = headSection[0] + styleSection;



            iframeHtml = headSection[0] + headSection[1];


            // if a layout template is passed in, strip out the default template and replace it
            if (settings.esriLayoutTemplate) {
                var headSectionLayout = iframeHtml.split("<body>");
                var bodySectionLayout = settings.esriLayoutTemplate;
                iframeHtml = headSectionLayout[0] + "<body>" + bodySectionLayout + "</body>";
            }




            // create iFrame element
            var iframeId = "iFrame" + Date.now();
            var iframe = document.createElement('iframe');
            iframe.id = iframeId;
            iframe.width = '100%';
            iframe.height = '100%';
            iframe.src = 'about:blank';     // making this a "friendly iFrame :: https://sparecycles.wordpress.com/2012/03/08/inject-content-into-a-new-iframe/


            // adjusting iFrame height to account for fusion-toolbar
            if (_numFusionToolBars > 0) {

                var toolbarHeightPercent = 5;          // in terms of percent
                var totalHeightPercent = toolbarHeightPercent * _numFusionToolBars;
                var adjustedHeight =  (100 - toolbarHeightPercent) + "%";
                

                iframe.height = adjustedHeight;
                iframe.className = "hasFusionToolBar";


                // also adjusting the containing element in control template to account for toolbars
                settings.esriPartHeight(adjustedHeight);

            }




            // put iFrame in doc
            //$markup.html(iframe);
            $markup.find(".esri-map-PART").html(iframe);
            //$markup.append(iframe);




            // write iframe HTML to the iframe element
            iframe.contentWindow.document.open('text/htmlreplace');
            iframe.contentWindow.document.write(iframeHtml);
            iframe.contentWindow.document.close();




            // when iframe is loaded, setup the ESRI object and call the callback funtion with ESRI object and map div reference
            //$(iframe).on('load',(function () { //UNDO Changes for JQuery 3.2.1
            $(iframe).load(function () {

                iframe.contentWindow.initialize(settings.esriServices).then(function (args) {
                //iframe.contentWindow.initialize(settings.esriServices).then(function () {

                    var iFrameRef = document.getElementById(iframeId);                    // ref to iFrame
                    var iFrameContentWindow = iFrameRef.contentWindow;                      // ref to iFrame window
                    var iFrameDoc = iFrameRef.contentDocument ? iFrameRef.contentDocument : iFrameRef.contentWindow.document;     // ref to iFrame document
                    var esri = iFrameContentWindow.esri;

                    var mapDivRef = iFrameDoc.getElementById("map");

                    args.unshift(mapDivRef);
                    //args.unshift(esri);
                    settings.initCallback.apply(null, args);
                    //settings.initCallback(esri, mapDivRef);
                });
            });
        }
    })
});


