define('fusion/ui/controls/fusion-container',["fusion/fusion.control"], function (ControlFactory) {

    return ControlFactory.control(function ($, ko, $log) {
        var c = this;

        c.settingsDefinition = {
            isBusy: { isLive: true, defaultValue: false }
        };


        //    var svgLoadingGraphic = '<svg version="1.1" class="loading-graphic " xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"' +
        //    'width="80px" height="80px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">' +
        //    '<path opacity="0.2" fill="#009ada" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946' +
        //    's14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634' +
        //    'c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />' +
        //    '<path fill="#009ada" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0' +
        //    'C22.32,8.481,24.301,9.057,26.013,10.047z">' +
        //        '<animateTransform attributeType="xml"' +
        //    'attributeName="transform"' +
        //    'type="rotate"' +
        //    'from="0 20 20"' +
        //    'to="360 20 20"' +
        //    'dur="1.5s"' +
        //    'repeatCount="indefinite" />' +
        //'</path>' +
        //'</svg>';

        var dropsheetHtml = '<div class="dropsheet"></div>';


        c.validateValues = function (settings) {
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            settings.isBusy.subscribe(function (newValue) {
                handleBusyChange(newValue, $markup, $, dropsheetHtml)
            });
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {

            // handling isBusy set when page loads
            //if (settings.isBusy) {
            //    setBusy($markup, $, dropsheetHtml);
            //}


        }




        function handleBusyChange(newValue, $markup, $, dropsheetHtml) {

            //if (newValue) {
            //    //$markup.parent().css("background-color", "rgba(0,0,0,0.3)").find('*').prop('disabled', true);
            //    //$markup.addClass("isBusy").find('*').prop('disabled', true);

            //    setBusy($markup, $, dropsheetHtml);

            //}
            //else {
            //    //$markup.parent().css("background-color", "transparent").find('*').prop('disabled', false);
            //    //$markup.removeClass("isBusy").find('*').prop('disabled', false);

            //    clearBusy($markup, $, dropsheetHtml);
            //}
        }


        function setBusy($markup, $, dropsheetHtml) {


            // append dropsheet to the container
            //$markup.children().wrapAll(dropsheetHtml);
            $(dropsheetHtml).insertBefore($markup.children());


            // disable child elements
            //$markup.find('.dropsheet').find('*').prop('disabled', true);


            // adding SVG graphic
            $(svgLoadingGraphic).insertBefore($markup.children(".dropsheet"));

        }


        function clearBusy($markup, $, dropsheetHtml) {
            var children = $markup.children(".dropsheet").children();
            $markup.html(children);

            //$markup.children(".dropsheet").unwrap();
        }

    });

});
