define("fusion/navigation", ['require','knockout','fusion/jquery','durandal/plugins/router','fusion/log','fusion/data','fusion/private/cache','fusion/utils','fusion/private/tea','fusion/config'],function (require) {
    "use strict";

    var ko = require("knockout");
    var $ = require("fusion/jquery");
    var router = require('durandal/plugins/router');
    var log = require("fusion/log");
    var data = require('fusion/data');
    var cache = require('fusion/private/cache');
    var utils = require('fusion/utils');
    var $utility = utils;
    var tea = require('fusion/private/tea');

    var $config = require("fusion/config");

    function getNavigationItems() {
        var dfd = $.Deferred();
        var promises = [];


        $config.navigation.forEach(function (navigationConfig) {
            promises.push(loadNavigationConfigFile(navigationConfig));      // pushing each navigation content to an array
        });

        $.when.apply(this, promises)
        .then(function () {
            var navigationItems = Array.prototype.concat.apply([], arguments);
            dfd.resolve(navigationItems);
        });
        return dfd.promise();

    }

    function loadNavigationConfigFile(navigationConfig) {
        var dfd = $.Deferred();

        if (navigationConfig) {
            // requiring as text so we get the actual content for processing
            //TODO:  add error handler in case this require call fails
            require(["text!" + navigationConfig], function (json) {
                var navigationItems;
                try {
                    // 20181024 : JEF - added regex to strip comments ( single line and multiline ) from nav.json files to prevent issues loading nav.json when users have comments in the file
                    json = json.replace(/\/\*[\s\S]*?\*\/|([^:]|^)\/\/.*$/mg, '');

                    navigationItems = JSON.parse(json);

                } catch (e) {
                    throw new Error("Error parsing " + navigationConfig + ".  If the file exists, ensure that the web.config is configured to serve the MIME type for the file extension.");
                    dfd.resolve([]);  // resolving w empty array in order to allow rest of process to continue even though no routes found
                }
                dfd.resolve(navigationItems);
            },
            function (err) {
                throw new Error("Could not find " + navigationConfig + ".  If the file exists, ensure that the web.config is configured to serve the json MIME type.");
                dfd.resolve([]);    // resolving w empty array in order to allow rest of process to continue even though no routes found
            });
        } else {
            dfd.resolve([]);    // resolving w empty array in order to allow rest of process to continue even though no routes found
        }

        return dfd.promise();
    }

    var options = {
        encrypt: false,
        /// <field name="urlParams" type="Boolean">Specifies whether the navigateTo function should use pass the parameters on the url 
        ///     instead of using the $cache service to pass parameters.  
        ///     If false, the entire parameter object will be cached, and and the value will be automatically retrieved in the modul_base.
        ///     If true, the entire parameter object will be passed on the url path.  If the parameter is an object, it will be
        //          serialized and encrypted.
        ///     An error will occur if the serialized parameter is too long to be passed in the url</field>
        urlParams: true,
        openNewTab: false
        /// <field name="openNewTab" type="Boolean"> Used by some navigation methods to determine if the navigation should open in a new tab.
    };

    function navigateToUrl(url, openNewTab) {
        /// <signature>
        /// <summary>
        /// Navigate to the url specified.   If openNewTab property is true, then the URL will be opened in a new tab
        /// </summary>
        /// <param name="url">Destination URL</param>
        /// <param name="openNewTab">Open URL in a new tab if set to true</param>
        /// </signature>

        if (openNewTab === null || openNewTab === undefined)
        {
            openNewTab = options.openNewTab;
        }

        try {
            //TODO: make this wait for the log to complete, then redirect
            log.analytic("UI", "NavigateToUrl", url);
            setTimeout(function () {

                if (openNewTab === false)
                {
                    window.location = url;
                }
                else 
                {
                    window.open(url, "blank"); 
                }
                
            }, 250);
        }
        catch (e) {
            log.error("Navigation to URL failed - " + e.message);
        }
    }


    function navigateTo(routeNameOrSettings, parameter) {
        /// <signature>
        /// <summary>Navigate to the specified location</summary>
        /// <param name="routeName" type="String">The name of the route to navigate to</param>
        /// </signature>
        /// <signature>
        /// <summary>Navigate to the specified location with parameters</summary>
        /// <param name="routeName" type="String">The name of the route to navigate to</param>
        /// <param name="parameter" type="Object">A parameter that should be passed as part of the route.  
        ///     Additional parameters can be passed as additional arguments to the navigateTo function.</param>
        /// </signature>
        /// <summary>Navigate to a route using the specified settings</summary>
        /// <param name="settings" type="Object">An object that contains options for the navigateTo operation.  
        ///     By default, the $navigation.options object will be used.
        /// </signature>
        /// <signature>
        /// <summary>Navigate to a route using the specified settings with parameters</summary>
        /// <param name="settings" type="Object">An object that contains options for the navigateTo operation.  
        ///     By default, the $navigation.options object will be used.
        /// <param name="parameter" type="Object">A parameter that should be passed as part of the route.  
        ///     Additional parameters can be passed as additional arguments to the navigateTo function.</param>
        /// </signature>
        /// </signature>

        var hash = getNavigationHash.apply(this, arguments);

        //perform navigation
        if (hash) {
            router.navigate(hash, true);
        }
    }


    function getRedirect(routeNameOrSettings, parameter) {
        /// <signature>
        /// <summary>Navigate to the specified location</summary>
        /// <param name="routeName" type="String">The name of the route to navigate to</param>
        /// </signature>
        /// <signature>
        /// <summary>Navigate to the specified location with parameters</summary>
        /// <param name="routeName" type="String">The name of the route to navigate to</param>
        /// <param name="parameter" type="Object">A parameter that should be passed as part of the route.  
        ///     Additional parameters can be passed as additional arguments to the navigateTo function.</param>
        /// </signature>
        /// <summary>Navigate to a route using the specified settings</summary>
        /// <param name="settings" type="Object">An object that contains options for the navigateTo operation.  
        ///     By default, the $navigation.options object will be used.
        /// </signature>
        /// <signature>
        /// <summary>Navigate to a route using the specified settings with parameters</summary>
        /// <param name="settings" type="Object">An object that contains options for the navigateTo operation.  
        ///     By default, the $navigation.options object will be used.
        /// <param name="parameter" type="Object">A parameter that should be passed as part of the route.  
        ///     Additional parameters can be passed as additional arguments to the navigateTo function.</param>
        /// </signature>
        /// </signature>

        var hash = getNavigationHash.apply(this, arguments);
        return { redirect: hash };
    }

    function getNavigationHash(routeNameOrSettings, parameter) {
        var settings = typeof routeNameOrSettings === 'object' ? routeNameOrSettings : $.extend({}, options, { routeName: routeNameOrSettings });
        var parameterCount = arguments.length - 1;
        var parameterCountForRoute = parameterCount;
        var hash = "";

        //map urlParams to viaCache for backwards compatibility.
        if (!$utility.isNullOrEmpty(settings.urlParams)) {
            settings.viaCache = !settings.urlParams;
        }

        //viaCache does not use route parameters
        if (settings.viaCache) {
            parameterCountForRoute = 0;
        }

        var routes = $.grep(router.routes,
		    function (e) {
		        //return routeExpTester.test(e.hash);
		        return e.routeNameRegExp.test(settings.routeName);
		    }
        );

        if (routes.length > 0) {
            hash = settings.routeName;

            //viaCache does not use route parameters
            if (!settings.viaCache) {

                //clean & apply the parameters
                for (var i = 0; i < parameterCount; i++) {
                    var cleanedParameterValue = prepareParameterValueForNavigation(arguments[i + 1], settings);
                    //replace the parameter placeholder, one at a time, from left to right.
                    hash += "/" + cleanedParameterValue;
                }

                //make sure there's not too much data on the querystring
                if (hash && hash.length > 2048) {
                    log.error("Too much data - url is too long.  Consider using in-memory parameters instead of using `urlParams=false` to pass them on the url");
                }

            } else {
                var cleanedParameters = [];
                for (var i = 0; i < parameterCount; i++) {
                    var cleanedParameterValue = prepareParameterValueForNavigation(arguments[i + 1], settings);
                    cleanedParameters.push(cleanedParameterValue);
                }
                //store in-memory on $navigation service.  The module_base will pick it up during the canActivate() function.
                $navigation.parameterValues = cleanedParameters;
            }
        }
        else {
            throw new Error("Navigation route not found for routeName: " + settings.routeName);
        }
        return hash;
    }

    function navigateBack() {
        /// <signature>
        /// <summary>Navigates to the previous page.  Analogous to window.history.back()</summary>
        /// </signature>
        window.history.back();
    }

    var $navigation = {
        options: options,
        activeItem: router.activeItem,
        afterCompose: router.afterCompose,
        navigationModel: router.navigationModel,
        activate: function (item) {
            if (!isActivated) {
                isActivated = true;
                /// <summary>A system method that executes when the view model is being activated.  Not intended to be called directly.</summary>
                router.activate(item);
            } else {
                throw new Error("Cannot call $navigate.activate more than once");
            }
        },

        //TODO: add ability to navigate and replace current active item
        navigateTo: navigateTo,
        navigateToUrl: navigateToUrl,
        navigateBack: navigateBack,
        getNavigationItems: getNavigationItems,
        getRedirect: getRedirect,
        init: init

    };


    function init() {

        var navigationItemsCreated = false;

        return $navigation.getNavigationItems()         // grabbing and loading nav files into an array on $config.navigation
            .done(function (items) {

                log.trace("navigation.getNavigationItems() resulted in " + (items ? items.length : "[null]") + " entries ");
                log.trace(items);

                var routeNameExp = /^(.*?)\/?(:.*)?$/i;    //parse out the routename from the route, excluding any parameters

                //process and map routes for navigation items in each area
                if (items && items.length > 0) {
                    for (var i = 0; i < items.length; i++) {
                        var item = items[i];

                        var routeArray;
                        if ($utility.isArray(item.route)) {
                            routeArray = item.route;
                        } else {
                            routeArray = [item.route];
                        }

                        routeArray.forEach(function (route) {
                            //parse and add routeNameRegExp property that can be used to match this route with route specified in navigateTo()
                            var match = routeNameExp.exec(route);  //extract just the route portion (excluding any parameters)
                            item.routeNameRegExp = new RegExp("^" + match[1].replace(/\*\w+/g, (".*?")) + "$", "i");

                            //call durandal's router.map() function to set this route
                            router.map(item);

                            //make sure each item has navHref property.  This will be used when building nav items for menu.
                            //This MUST come AFTER route.map() call, so that the item.hash property has been populated.
                            item.navHref = item.navHref || item.hash;
                        });

                    }

                    router.buildNavigationModel();
                }

            });
    };



    //take a parameter value and either cache it and return the key, or prepare it for the querystring
    function prepareParameterValueForNavigation(parameterValue, settings) {

        //make sure we clean the parameter value.  We can't allow or risk passing of functions
        //  via navigation parameters
        parameterValue = ko.utils.unwrapObservable(parameterValue);

        if (typeof parameterValue === "function") {
            throw new Error("Functions are not allowed as navigation parameters.");
        }

        if (settings.viaCache) {
            //if passing parameter in-memory, then use the parameter value as-is.
            return parameterValue;
        } else {

            //if passing on url, then we need to proces the value a bit to make sure it works on the url.

            if (parameterValue != null && (settings.encrypt || typeof parameterValue === "object")) {
                //convert values JSON
                parameterValue = { val: parameterValue };
                parameterValue = ko.toJSON(parameterValue);
                //encrypt (complex object parameters are always encrypted to minimize exposure)
                parameterValue = tea.encrypt(parameterValue, fusion.teaKey);
            }
            return encodeURIComponent(parameterValue);
        }
    }

    var isActivated = false;

    return $navigation;

});

