define("fusion/private/dialog", ['require','knockout','durandal/plugins/dialog','fusion/jquery'],function (require) {
    "use strict";

    var ko = require("knockout");
    var dialog = require("durandal/plugins/dialog");
    var $ = require("fusion/jquery");

    //add custom context so we can tweak the display logic for our HTML
    dialog.addContext('fusion', {
        blockoutOpacity: .5,
        removeDelay: 200,
        /**
         * In this function, you are expected to add a DOM element to the tree which will serve as the "host" for the modal's composed view. You must add a property called host to the modalWindow object which references the dom element. It is this host which is passed to the composition module.
         * @method addHost
         * @param {Dialog} theDialog The dialog model.
         */
        addHost: function (theDialog) {

            var body = $('body');

            var host = $('<div id="myModal" class="reveal-modal fusion-dialog" data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog"></div>')
                .appendTo(body);

            theDialog.host = host.get(0);
        },

        attached: function (view) {
            //$('.reveal-modal-bg').remove();
        },

        /**
         * This function is expected to remove any DOM machinery associated with the specified dialog and do any other necessary cleanup.
         * @method removeHost
         * @param {Dialog} theDialog The dialog model.
         */
        removeHost: function (theDialog) {
            var t = this;

            $(theDialog.host).foundation('reveal', 'close');

            setTimeout(function () {
                ko.removeNode(theDialog.host);
                ko.removeNode(theDialog.blockout);
            }, this.removeDelay);
        },
        /**
         * This function is called after the modal is fully composed into the DOM, allowing your implementation to do any final modifications, such as positioning or animation. You can obtain the original dialog object by using `getDialog` on context.model.
         * @method compositionComplete
         * @param {DOMElement} child The dialog view.
         * @param {DOMElement} parent The parent view.
         * @param {object} context The composition context.
         */
        compositionComplete: function (child, parent, context) {

            var theDialog = dialog.getDialog(context.model);

            //TODO : might need to wrap this in a condition is the dialog should not be closed by clicking on close button or BG - i.e. force yes/no answer.  Also have to handle BG click a bit lower down too
            //if (theDialog.options.canDismiss) {
                var closeBtn = $('<a class="close-reveal-modal" aria-label="Close">&#215;</a>');
                closeBtn.appendTo(theDialog.host);
                closeBtn.click(function () {
                    theDialog.close();
                });
            //}

            // once dialog is attached to the DOM, call reflow for Foundation to reapply bindings
            $(document).foundation('reflow');

            // call for the modal to open
            $(theDialog.host).foundation('reveal', 'open', {
                multiple_opened: true
            });

            // set dialog blockout to Foundation's BG hide DIV so we can associate click/close click handler
            theDialog.blockout = $(".reveal-modal-bg")[0];


            //if (theDialog.options.canDismiss) {
                $(theDialog.blockout).click(function () {
                    theDialog.close();
                });
            //}

            $('.autofocus', child).each(function () {
                $(this).focus();
            });

        }
    });

    // showing custom Fx standard dialog
    function show(dialogTitle, dialogMessage, dialogButtons, dialogOptions) {

        // 7/13/18 - sh46000
        // Supports immediate opening of second dialog after a first is closed.
        // Issue came from elements still existing in the DOM, and because foundation's
        // open/close reveal acts like a toggle, the background blur got stuck.
        $('.reveal-modal-bg').remove();
        $('.fusion-dialog').remove();

        //standard message box dialog
        var moduleId = "durandal/dialogs/default-dialog";  // this is dialog view Fx team created - this template is using params from the activationData var

        //TODO : find a way to handle dialog options wihtin the dialog context
        var activationData = ({ question: dialogMessage, title: dialogTitle, buttons: dialogButtons });

        // showFusion is Durandal dialog's way of calling the fusion context we created when initializing
        var dialogPromise = dialog.showFusion(moduleId, activationData);
        return dialogPromise;
    }

    // showing dialog using a view/viewmodel pair - developer's custom dialog
    function showCustom(moduleId) {

        // 7/13/18 - sh46000
        // Supports immediate opening of second dialog after a first is closed.
        // Issue came from elements still existing in the DOM, and because foundation's
        // open/close reveal acts like a toggle, the background blur got stuck.
        $('.reveal-modal-bg').remove();
        $('.fusion-dialog').remove();

        //get array containins all arguments except the first, if they are passed -- args passed in to this method are passed to dialog as acticationData
        var activationData = Array.prototype.slice.call(arguments, 1);

        var dialogPromise = dialog.showFusion(moduleId, activationData);
        return dialogPromise;
    }

    function close(obj, result) {
        dialog.close(obj, result);
    }

    return {
        show: show,
        showCustom: showCustom,
        close: close
    };

});
