define('fusion/ui/controls/fusion-list',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function (ko, $) {
        var c = this;

        var itemBuffer = [];
        var itemPrevSize = 0;
        var itemBufferPrevSize = 0;
        //var listHeight = 10;

        c.settingsDefinition = {
            items: { isLive: true, defaultValue: ko.observableArray([]) },
            headerTemplate: { isLive: true },
            footerTemplate: { isLive: true },
            itemTemplate: { isLive: true },
            isZebraStriped: { defaultValue: true },
            maxDisplayItems: { defaultValue: 0 },
            scrollEndCallback: { isLive: false, defaultValue: null } // this needs to be a promise
        };


        c.validateValues = function (settings) {
            settings("isZebraStriped").ensureObservable();
            settings("isZebraStriped").throwIfNot().isOneOf([true, false]);
            settings("items").throwIf().isNullOrEmpty();
       } 

        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            //don't allow both items property and specifying items inline.
            if (settings.items() && settings.items().length > 0 && $element.find("fusion-list-item").length > 0) {
                throw new Error("Error in " + settings.__bindingType + ".  Cannot specify both the items property and also <fusion-list-item> elements.");
            }


            ////attach the getZebraClass function to the settings object so that we can bind to it.
            //settings.getZebraClass = getZebraClass.bind(this, settings);

            if (settings.maxDisplayItems > 0) {
                settings.items.subscribe(function (newValue) {
                    var deltaItems = Math.abs(newValue.length - itemPrevSize);
                    var deltaBuffer = Math.abs(itemBuffer.length - itemBufferPrevSize);

                    // if the items changed without the buffer changing, we need to refresh the buffer
                    if (deltaItems !== deltaBuffer) {
                        itemBuffer = [];
                        itemBufferPrevSize = 0;

                        if (newValue.length > settings.maxDisplayItems) {
                            itemBuffer = settings.items().splice(settings.maxDisplayItems, settings.items().length - settings.maxDisplayItems);
                            itemPrevSize = settings.maxDisplayItems;
                            itemBufferPrevSize = itemBuffer.length;
                        } else { // if the new size is fewer than the max display items, that's all there is.
                            itemPrevSize = newValue.length;
                        }
                    } else { // otherwise, we want to update the items and item buffer's previous sizes
                        itemPrevSize = newValue.length;
                        itemBufferPrevSize = itemBuffer.length;
                    }
                });

                // trying to find a way to scale the number of list items shown based on the window size.
                //var maxHeight = Math.ceil($(window).height() * 1.33);
                //listHeight = Math.ceil(maxHeight / 132);

                if (settings.items().length > settings.maxDisplayItems) {
                    itemBuffer = settings.items().splice(settings.maxDisplayItems, settings.items().length - settings.maxDisplayItems);
                    itemPrevSize = settings.maxDisplayItems;
                    itemBufferPrevSize = itemBuffer.length;
                }



                function scroller() {
                    //don't scroll unless visible.  This will keep hidden lists (on other views) from trying to scroll.
                    if (!$markup.is(':visible')) {
                        return;
                    }

                    if ($(window).scrollTop() + $(window).height() === $(document).height()) {
                        settings.items.push.apply(settings.items, itemBuffer.splice(0, settings.maxDisplayItems));

                        if (itemBuffer.length === 0) {
                            if (settings.scrollEndCallback !== null) {
                                settings.scrollEndCallback().done(function (results) {
                                    itemBuffer = results;
                                    itemBufferPrevSize = itemBuffer.length;
                                });
                            }
                        }
                    }
                }



                $(window).scroll(scroller);

                ko.utils.domNodeDisposal.addDisposeCallback($markup[0], function () {
                    // This will be called when the element is removed by Knockout or
                    // if some other part of your code calls ko.removeNode(element)
                    $(window).off('scroll', scroller);
                });

            }
        }


        //function getZebraClass(settings, rowIndex) {
        //    var hasHeader = !!settings.headerTemplate();

        //    if (settings.isZebraStriped()) {
        //        if (rowIndex % 2) {
        //            return hasHeader ? "odd" : "even";
        //        } else {
        //            return hasHeader ? "even" : "odd";
        //        }
        //    }
        //}

        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {

        };

    });
});
