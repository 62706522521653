define('fusion/utils',["require", "fusion/jquery", "fusion/private/tea", "jquery.cookie"], function (require, $, tea) {
    "use strict";

    //var $ = require("fusion/jquery");

    //var tea = require("fusion/private/tea");
    //var log = require("fusion/log");
    //var data = require("fusion/data");

    //require("jquery.cookie");




    // #region Date related

    Date.prototype.addDays = function (days) {
        this.setDate(this.getDate() + days);
        return this;
    };

    Date.prototype.format = function (separator) {
        if (!separator || separator.length < 1) { separator = '/'; }
        return (Number(this.getMonth()) + 1) + separator + this.getDate() + separator + this.getFullYear();
    };


    function getMonthLabel(monthNumber) {
        /// <signature>
        /// <summary>Returns an object with standardized month labels for different scenarios, i.e. single letter versus three letter abbreviation.</summary>
        /// <param name="monthNumber">Integer ranging from 0 - 11</param>
        /// <returns type="">Object</returns>
        /// </signature>

        try {

            var mon = parseInt(monthNumber);

            if (mon >= 0 && mon <= 11) {

                var months = [
                    { singleLetterLabel: "J", shortLabel: "Jan", fullLabel: "January" },
                    { singleLetterLabel: "F", shortLabel: "Feb", fullLabel: "February" },
                    { singleLetterLabel: "M", shortLabel: "Mar", fullLabel: "March" },
                    { singleLetterLabel: "A", shortLabel: "Apr", fullLabel: "April" },
                    { singleLetterLabel: "M", shortLabel: "May", fullLabel: "May" },
                    { singleLetterLabel: "J", shortLabel: "Jun", fullLabel: "June" },
                    { singleLetterLabel: "J", shortLabel: "Jul", fullLabel: "July" },
                    { singleLetterLabel: "A", shortLabel: "Aug", fullLabel: "August" },
                    { singleLetterLabel: "S", shortLabel: "Sep", fullLabel: "September" },
                    { singleLetterLabel: "O", shortLabel: "Oct", fullLabel: "October" },
                    { singleLetterLabel: "N", shortLabel: "Nov", fullLabel: "November" },
                    { singleLetterLabel: "D", shortLabel: "Dec", fullLabel: "December" }
                ];

                return months(mon);
            }
            else {
                throw new Error("Month number must be an integer between 0 and 11");
            }
        }
        catch (e) {
            $log("$utils.getMonthLabel failed - " + e.message);
        }
    }

    // #endregion Date related






    // #region String related functions
    function trim(stringInput) {
        /// <signature>
        /// <summary>Trim whitespace from the beginning and end of the string argument.</summary>
        /// <param name="stringInput" type="String">The string to be trimmed</param>
        /// <returns type="String" />
        /// </signature>
        var returnStr = "";

        if (stringInput)                                                                    // ensuring valid input
        {
            stringInput = stringInput.toString();
            if (!String.prototype.trim)                                                     // checking for lack of native trim functionality
            {
                returnStr = stringInput.toString().replace(/^([\s]*)|([\s]*)$/g, '');
            }
            else {
                returnStr = stringInput.trim();
            }

            return returnStr;
        }
        else {
            return stringInput;
        }

    }

    function endsWith(stringInput, suffix) {
        /// <signature>
        /// <summary>Returns a boolean value indicating whether the suffix is at the end of the string input.  Returns false if either input is null, undefinedm, or a non-string</summary>
        /// <param name="stringInput" type="string">Input to be checked</param>
        /// <param name="suffix" type="string">Used to determine whether the stringInput ends with this string value</param>
        /// <returns type="Boolean" />
        /// </signature>

        if (suffix !== null && stringInput !== null &&
            !(typeof suffix !== "string" && typeof stringInput !== "string") &&
            (typeof suffix !== "undefined" && typeof stringInput !== "undefined")) {
            return stringInput.indexOf(suffix, stringInput.length - suffix.length) !== -1;
        }
        else {
            return false;
        }
    }

    function startsWith(stringInput, prefix) {
        /// <signature>
        /// <summary>Returns a boolean value indicating whether the prefix is at the beginning of the string input.  Returns false if either input is null, undefined, or a non-string</summary>
        /// <param name="stringInput" type="string">Input to be checked</param>
        /// <param name="prefix" type="string">Used to determine whether the stringInput starts with this string value</param>
        /// <returns type="Boolean" />
        /// </signature>

        if (typeof stringInput === "string" && !isNullOrEmpty(stringInput)) {
            return stringInput.indexOf(prefix) === 0;
        } else {
            return false;
        }
    }

    function isNullOrEmpty(value) {
        /// <signature>
        /// <summary>Returns a boolean value indicating whether the specified value is null, undefined, or empty string</summary>
        /// <param name="value" type="Object">The value to be evaluted</param>
        /// <returns type="Boolean" />
        /// </signature>
        return isNull(value) || value === "";
    }

    // #endregion String related functions


    function isNull(value) {
        /// <signature>
        /// <summary>Returns a boolean value indicating whether the specified value is null or undefined</summary>
        /// <param name="value" type="Object">The value to be evaluted</param>
        /// <returns type="Boolean" />
        /// </signature>
        return value === void 0 || value === null;
    }

    function isNullOrWhitespace(value) {
        /// <signature>
        /// <summary>Returns a boolean value indicating whether the specified value is null or consistes entirely of white-space characters.</summary>
        /// <param name="value" type="Object">The value to be evaluated</param>
        /// <returns type="Boolean" />
        /// </signature>
        return isNullOrEmpty(value) || trim(value).length === 0;
    }

    //validates string dates in this format mm/dd/yyyy
    //function isDate(dateString) {
    //    // if this is not the correct date format then return false
    //    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    //        return false;
    //    }

    //    // Parse the date parts to integers
    //    var parts = dateString.split("/");
    //    var month = parseInt(parts[0], 10);
    //    var day = parseInt(parts[1], 10);
    //    var year = parseInt(parts[2], 10);

    //    // Check the ranges of month and year
    //    if (year < 1900 || year > 2200 || month == 0 || month > 12) {
    //        return false;
    //    }

    //    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    //    // Adjust for leap years
    //    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    //        monthLength[1] = 29;

    //    // Check the range of the day - if it all checks out then respond that its a good date
    //    return day > 0 && day <= monthLength[month - 1];
    //}

    function isDate(d) {
        if (Object.prototype.toString.call(d) !== "[object Date]")
            return false;
        return !isNaN(d.getTime());
    }


    function isInteger(value) {
        /// <signature>
        /// <summary>Returns a boolean value indicating whether the specified value is an integer</summary>
        /// <param name="value" type="Object">The value to be evaluated</param>
        /// <returns type="Boolean" />
        /// </signature>

        // converting to an integer or float if value is a string
        var item;
        if (typeof (value) === "string") {
            // check for a period in the string -- treat as a float if so
            item = (value.search(/\./) > 0) ? parseFloat(value) : parseInt(value);
        }
        else {
            item = value;
        }
        var result = !isNaN(item) && Math.floor(item) === item;
        return result;
    }


    function tryParseBool(val) {
        /// <signature>
        /// <summary>Attempts to resolve parameter passed to a boolean value.  Success returns true, otherwise false is returned</summary>
        /// <param name="value" type="Object">The value to be evaluated</param>
        /// <returns type="Boolean" />
        /// </signature>
        return (val === true || val === 'true' || val === 1 || val === "1");

    }

    // **********************************************
    //   Url
    // **********************************************
    // #region URL related functions
    function removeLeadingSlash(url) {
        /// <signature>
        /// <summary>Remove slash, if it exists, from the beginning of the specified url.</summary>
        /// <param name="url" type="String">The url to process</param>
        /// <returns type="String" />
        /// </signature>
        if (url) {
            return url.toString().replace(/^\//, '');
        }
        else {
            return url;
        }
    }

    function removeTrailingSlash(url) {
        if (url.length > 0 && url.substr(url.length - 1) == '/') {
            return url.substr(0, url.length - 1);
        }
        return url;
    }

    function ensureTrailingSlash(url) {
        /// <signature>
        /// <summary>Ensure the specified url ends with a slash.</summary>
        /// <param name="url" type="String">The url to process</param>
        /// <returns type="String" />
        /// </signature>
        var url = url || "";

        // convert the input to a string if it is not to avoid missing indexOf error
        if (typeof url !== "string") {
            url = String(url);
        }

        if (!endsWith(url, "/")) {
            url += "/";
        }
        return url;
    }
    // #endregion URL related functions



    // **********************************************
    //   Phone Number related
    // **********************************************
    //#region phone number formatting
    function formatPhoneNumber(rawPhoneNumber) {
        // making sure rawPhoneNumber is not null, etc. - assigning to empty string if so
        rawPhoneNumber = rawPhoneNumber || "";

        //if nothing passed in, then don't try to format it.
        if (!rawPhoneNumber) {
            return "";
        }

        var inProcNumber = "";
        var formattedNumber = "";

        var nonDigitsPatt = /\D/g;

        var nonDigits = rawPhoneNumber.match(nonDigitsPatt);

        // check for other bad input
        if ((nonDigits && nonDigits.length > 0) || rawPhoneNumber.length < 10 || rawPhoneNumber.length > 11) {
            throw Error("Invalid phone number passed to utils.formatPhoneNumber :: " + rawPhoneNumber);
        }

        // determine if there is a leading "1" and add it if there isn't
        if (rawPhoneNumber.length === 10) {
            inProcNumber = "1" + rawPhoneNumber;
        }
        else if (rawPhoneNumber.length === 11) {
            inProcNumber = rawPhoneNumber;
        }

        // format the phone number
        formattedNumber = inProcNumber.substr(0, 1) + "-" + inProcNumber.substr(1, 3) + "-" + inProcNumber.substr(4, 3) + "-" + inProcNumber.substr(7);

        return formattedNumber;
    }
    //#endregion phone number formatting




    //#region Applet related

    function getAppletResource(relativePath) {
        /// <signature>
        /// <summary>Provides a way to retrieve a safe path for resources housed in an applet, i.e. an applet specific image.</summary>
        /// <param name="relativePath" type="string">Path safe string to the resource requested that works at both an applet test harness level and a host site level.</param>
        /// <returns type="String" />
        /// </signature>

        //
        if (this.string.startsWith(relativePath, "/")) {
            throw new Error("Path to applet resource begins with a slash -- the resource <" + relativePath + "> will not be retrieved correctly.");
        }

        return require.toUrl(relativePath);
    }



    function getClientInteractionId() {
        /// <summary>
        /// 
        /// </summary>

        var data = require("fusion/data");

        var dfd = $.Deferred();

        // getting client interaction ID from the FW
        data.get({
            action: "GetClientInteractionId",
            controller: "CisFrameworkApi",
            cache: true
        })
        .done(function (clienterInteractionId) {
            dfd.resolve(clienterInteractionId);
        })
        .fail(function () {
            dfd.resolve(null);
        });


        return dfd.promise();

    }

    //#endregion Applet related



    function waitFor() {
        /// <signature>
        /// <summary>Provides a way to execute callback functions based on one or more objects, usually Deferred objects that represent asynchronous events</summary>
        /// <param name="deferred" type="Deferred">One or more Deferred objects, JavaScript functions, or plain JavaScript objects</param>
        /// <returns type="Promise" />
        /// </signature>
        var deferreds = [];
        Array.prototype.slice.call(arguments, 0).forEach(function (item) {
            deferreds.push((typeof item === "function") ? item() : item);
        });
        return $.when.apply(this, deferreds);
    }



    function handleEnterKey(fn)
    {
        //TODO: Check if user passes in a fn. 
        $(document).on("keypress", function (e) {

            var key = (e.keyCode) ? e.keyCode : e.which;
            if (key === 13) {
                fn(e);      // passing the event to call back function
            }
        });

    }


    /**
     * Creates a deferred object which can be used to create a promise. Optionally pass a function action to perform which will be passed an object used in resolving the promise.
     * @method defer
     * @param {function} [action] The action to defer. You will be passed the deferred object as a paramter.
     * @return {Deferred} The deferred object.
     */
    function defer(action) {
        return $.Deferred(action);
    }


    function formatCurrency(val) {
        var isNegative = new Number(val) < 0;
        var numericVal = Math.abs(new Number(val)).toFixed(2);
        var formattedValue = "";
        if (numericVal !== "NaN") {

            if (isNegative) {
                //prefix negative values with "minus" before the dollar sign
                formattedValue += "-";
            };
            //add dollar sign and add thousands separator
            formattedValue += '$' + (numericVal.replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));
        }
        return formattedValue;
    }




    function createGuid() {

        var d = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
    }


    // utility function to retrieve current line number :: http://stackoverflow.com/questions/2343343/how-can-i-determine-the-current-line-number-in-javascript
    function getCurrentLineNumber() {
        try {
            var e = new Error();
            if (!e.stack) try {
                // IE requires the Error to actually be throw or else the Error's 'stack' property is undefined.
                throw e;
            } catch (e) {
                if (!e.stack) {
                    return 0; // IE < 10, likely
                }
            }
            var stack = e.stack.toString().split(/\r\n|\n/);
            // We want our caller's frame. It's index into |stack| depends on the
            // browser and browser version, so we need to search for the second frame:
            var frameRE = /:(\d+):(?:\d+)[^\d]*$/;
            do {
                var frame = stack.shift();
            } while (!frameRE.exec(frame) && stack.length);
            return frameRE.exec(stack.shift())[1];
        }
        catch (e) {
            return 0;
        }
    }




    return {
        /// <field name="common" type="Object">An object that provides string related utility functions</field>
        string: {
            startsWith: startsWith,
            endsWith: endsWith,
            trim: trim,
            formatPhoneNumber: formatPhoneNumber
        },
        url: {
            removeLeadingSlash: removeLeadingSlash,
            ensureTrailingSlash: ensureTrailingSlash,
            removeTrailingSlash: removeTrailingSlash
        },

        isNull: isNull,
        isNullOrEmpty: isNullOrEmpty,
        isNullOrWhitespace: isNullOrWhitespace,
        isInteger: isInteger,
        tryParseBool: tryParseBool,
        isDate: isDate,
        isArray: Array.isArray,
        cookie: $.cookie,
        removeCookie: function (key) { var cookie = $.cookie(key); $.removeCookie(key); return cookie; },
        waitFor: waitFor,
        encrypt: tea.encrypt,
        decrypt: tea.decrypt,
        merge: function (o1, o2) { return $.extend({}, o1, o2); },
        getAppletResource: getAppletResource,
        getClientInteractionId: getClientInteractionId,
        handleEnterKey: handleEnterKey,
        defer: defer,
        formatCurrency: formatCurrency,
        createGuid: createGuid,
        getCurrentLineNumber: getCurrentLineNumber
    };

});
