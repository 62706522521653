"use strict";
define('fusion/fusion.control',['require','fusion/factory-helper','module'],function (require) {

    var factoryHelper = require("fusion/factory-helper");

    // Set window.fusion.control as old define() function
    // in case user creates custom control
    window.fusion.control = function (options, f) {

        if (arguments.length === 1) {
            f = options;
            options = void 0;
        }


        //parse out an array of dependencies from the factory function
        var deps = factoryHelper.parseDependencies(f);
        var devDependencyCount = deps.length;

        //parse out an array of the argument names that the function receives
        var args = factoryHelper.argumentNames(f);

        //if extendind another control, we need to add it as a dependency so that it is pre-loaded and available
        //  when the define callback function executes.
        if (options && options.extend) {
            var extendeePath = getExtendeePath(options.extend);
            deps.unshift(extendeePath);
        }

        //finally, insert the "require" dependency at the beginning of the array
        //  so that we have access to it as the first argument in the upcoming "define" call
        deps.unshift("require");

        //"define" the module
        define(deps, function (require) {
            var module = require("module");

            //if there are any custom dependencies found, then make sure the developer
            //  is taking "require" as an argument name.  If not, then the
            //  require() call won't execute in the correct context.
            if (devDependencyCount > 0 && args.indexOf("require") === -1) {
                throw new Error("Error in " + module.id + ".  When calling require(), 'require' must also be specified as an argument in the factory function.");
            }


            //create a view model function
            var FusionControl = function () {
                //get array of services according to the argument list 
                //  in the factory function
                var services = factoryHelper.getServices(args, require, this);

                f.apply(this, services);
            };

            //extend another control, if specified
            if (options && options.extend) {

                var extendeePath = getExtendeePath(options.extend);
                var extendee = require(extendeePath);
                FusionControl.prototype = extendee;
                FusionControl.prototype.constructor = f;

            }

            //controls are always singletons, because the control
            //  functions themselves don't maintain any internal state for  except for controlSettings
            var controlDefinitionInstance = new FusionControl();    //  not the actual control on the page, the instance of the definition 

            controlDefinitionInstance.getBase = function () {
                //TODO: put check here to help ensure that this function is called in correct context 
                //  (e.g. so that `this` refers to derived instance)
                var p = Object.getPrototypeOf(this);

                return p;
            };

            //set the options as property on control, because the fusion-control.js will
            //  need access to the options to determine how to load the template
            controlDefinitionInstance.extendOptions = options;

            // returning an actual obj rather than a function - important b/c requireJS will always return a specific "thing", which is an actual 
            // instance here rather than a function to create new things
            return controlDefinitionInstance;
        });

    }

    /**
     * ADDED ** v6.0.0
     * Bundling controls instead of requiring them from the cdn
     * requires us to wrap them in a define() call of some kind,
     * so that they can be bundleded correctly and registered with
     * require. We expose a "factory" function here that creates a new
     * instance of a fusion control, but does not define() it like the
     * old fusion.control did. 
     */
    function createControl(options, f) {

        if (arguments.length === 1) {
            f = options;
            options = void 0;
        }

        //parse out an array of dependencies from the factory function
        var deps = factoryHelper.parseDependencies(f);
        var devDependencyCount = deps.length;

        //parse out an array of the argument names that the function receives
        var args = factoryHelper.argumentNames(f);

        //if extendind another control, we need to add it as a dependency so that it is pre-loaded and available
        //  when the define callback function executes.
        if (options && options.extend) {
            var extendeePath = getExtendeePath(options.extend);
            deps.unshift(extendeePath);
        }

        // finally, insert the "require" dependency at the beginning of the array
        // so that we have access to it as the first argument in the upcoming "define" call
        //deps.unshift("require");

        // for each dependency, require it
        deps.forEach(function (dep) {
            console.warn(dep);
            require(dep);
        });

        //var module = require("module");
        var module = { id: "test" };

        //if there are any custom dependencies found, then make sure the developer
        //  is taking "require" as an argument name.  If not, then the
        //  require() call won't execute in the correct context.
        if (devDependencyCount > 0 && args.indexOf("require") === -1) {
            throw new Error("Error in " + module.id + ".  When calling require(), 'require' must also be specified as an argument in the factory function.");
        }

        //create a view model function
        var FusionControl = function () {
            //get array of services according to the argument list 
            //  in the factory function
            var services = factoryHelper.getServices(args, require, this);

            f.apply(this, services);
        };

        //extend another control, if specified
        if (options && options.extend) {

            var extendeePath = getExtendeePath(options.extend);
            var extendee = require(extendeePath);
            FusionControl.prototype = extendee;
            FusionControl.prototype.constructor = f;

        }

        //controls are always singletons, because the control
        //  functions themselves don't maintain any internal state for  except for controlSettings
        var controlDefinitionInstance = new FusionControl();    //  not the actual control on the page, the instance of the definition 

        controlDefinitionInstance.getBase = function () {
            //TODO: put check here to help ensure that this function is called in correct context 
            //  (e.g. so that `this` refers to derived instance)
            var p = Object.getPrototypeOf(this);

            return p;
        };

        //set the options as property on control, because the fusion-control.js will
        //  need access to the options to determine how to load the template
        controlDefinitionInstance.extendOptions = options;

        // returning an actual obj rather than a function - important b/c requireJS will always return a specific "thing", which is an actual 
        // instance here rather than a function to create new things
        return controlDefinitionInstance;
    }

    //TODO: Consolidate this with same functionality in ui\fusioncontrol.js
    function getExtendeePath(controlName) {
        return "fusion/ui/controls/" + controlName;
    }

    // Return factory function for fx to trace dependencies
    return {
        control: createControl
    };

});
