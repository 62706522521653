define('fusion/ui/controls/fusion-alertbox',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko) {
        var c = this;


        c.settingsDefinition = {
            alertType: { isRequired: true }
        };






        c.validateValues = function (settings) {

            settings("alertType").throwIfNot().isOneOf(['warning', 'danger']);

        }



        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            settings.iconCssClass = ko.computed(function () { return 'icon ' + ko.unwrap(settings.alertType); });

            settings.closeAlert = function () {

                // test for event.target is for IE9
                var parent = (event.target) ? $(event.target).parent() : $(event.srcElement).parent();

                parent.animate({
                    opacity: 0.0
                }, 550, function () {
                    parent.attr("style", "display:none")
                });
            };

        }



        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }



        c.afterDomInsert = function ($markup, settings, bindingContext) {

        }




    });
});
