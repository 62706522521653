define('fusion/ui/controls/fusion-slideout',[
    'fusion/fusion.control',
    'durandal/plugins/router',
    'fusion/private/settings-validator'
], function (ControlFactory, router, settingsValidator) {
    return ControlFactory.control(function ($, ko, require, $log, $utility) {
        var c = this;

        var _sizes = {
            small: '30%',
            medium: '50%',
            large: '80%',
            full: '100%'
        };

        var _sides = {
            left: 'left',
            right: 'right'
        };        

        c.settingsDefinition = {
            isOpen: { isLive: true, defaultValue: false },
            options: { isLive: false, defaultValue: {}}
        };

        c.validateValues = function (settings) {
        };

        c.beforeBind = function ($markup, settings, bindingContext, $element) {            
            
            var defaultOptions = {
                width: _sizes.small,
                side: _sides.right,
                transitionTime: 150,
                closeOnNav: true,
                showCloseButton: true,
                showDropsheet: true,
                showTab: false,
                clickDropsheetToClose: true,
                showAtPageLevel: true,
            };

            // Merge default options with user-defined, taking user-defined over
            // defaults. Don't make them observable if they aren't already. This might
            // cause confusion for developer trying to call options.property in the viewModel
            // and get back a function (observable).
            settings.options = settings.options || {};
            for (var prop in defaultOptions) {
                if (!settings.options.hasOwnProperty(prop)) {
                    settings.options[prop] = defaultOptions[prop];
                }
            }

            validateOptions(settings.options);            

            if (ko.unwrap(settings.options.closeOnNav)) {
                router.on('router:navigation:processing', function () {
                    settings.isOpen(false);
                });
            }

            // #region open/close/toggle functions
            settings.close = function () {
                settings.isOpen(false);
            };

            settings.open = function () {
                settings.isOpen(true);
            };

            settings.toggle = function () {
                settings.isOpen(!settings.isOpen());
            };

            settings._closeDropsheet = function () {
                if (ko.unwrap(settings.options.clickDropsheetToClose)) {
                    settings.isOpen(false);
                }
            };
            // #endregion open/close/toggle functions

            // Setup the style and class bindings here so they don't clutter up the html
            settings._panelStyles = {
                transition: ko.computed(function () {
                    return ko.unwrap(settings.options.transitionTime) + 'ms';
                }),
                width: ko.computed(function () {
                    return 'calc(' + _sizes[ko.unwrap(settings.options.width)] + ')';
                }),
                right: ko.computed(function () {
                    if (ko.unwrap(settings.options.side) === 'right') {
                        return !settings.isOpen() ? 'calc(-' + _sizes[ko.unwrap(settings.options.width)] + ')' : '0';
                    } else {
                        return 'unset';
                    }
                }),
                left: ko.computed(function () {
                    if (ko.unwrap(settings.options.side) === 'left') {
                        return !settings.isOpen() ? 'calc(-' + _sizes[ko.unwrap(settings.options.width)] + ')' : '0';
                    } else {
                        return 'unset';
                    }
                })
            };

            // #region Binding handler to handle dropsheet fade in/out
            ko.bindingHandlers.fadeDropsheet = {
                init: function (element, valueAccessor) {
                    // Set initial visibility to on/off
                    var value = valueAccessor();
                    $(element).toggle(ko.unwrap(value));
                },
                update: function (element, valueAccessor) {
    
                    var value = valueAccessor();
                    var transitionTime = parseInt(ko.unwrap(settings.options.transitionTime));
        
                    if (!!ko.unwrap(value)) {
                        $(element).css('opacity', '1');
                        $(element).fadeIn(transitionTime);
                    } else {
                        $(element).fadeOut(transitionTime);
                    }
                }
            };
            // #endregion Binding handler to handle dropsheet fade in/out

        };

        c.afterBind = function ($markup, settings, bindingContext, $element) {

        };

        c.afterDomInsert = function ($markup, settings, bindingContext) {
        };


        function validateOptions(options){
        
            // Because we're nesting the options inside an "options" property on
            // settings, we can require settings-validator manually and setup a new
            // validator object on the options property.
            var validator = new settingsValidator(options, 'fusion-slideout');

            validator('width').throwIfNot().isOneOf(Object.keys(_sizes));
            validator('side').throwIfNot().isOneOf(Object.keys(_sides));
            validator('transitionTime').throwIfNot().isInteger();
            validator('closeOnNav').throwIfNot().isBoolean();
            validator('showCloseButton').throwIfNot().isBoolean();
            validator('showDropsheet').throwIfNot().isBoolean();
            validator('showTab').throwIfNot().isBoolean();
            validator('clickDropsheetToClose').throwIfNot().isBoolean();
            validator('showAtPageLevel').throwIfNot().isBoolean();
        } 
        
    });
});
