"use strict";
define('fusion/ui/controls/fusion-datatable',[
        "fusion/fusion.control",

        'css!js/ext/DataTables/DataTables-1.10.18/css/jquery.dataTables.min.css',
        'css!js/ext/DataTables/AutoFill-2.3.3/css/autoFill.dataTables.min.css',
        'css!js/ext/DataTables/Buttons-1.5.6/css/buttons.dataTables.min.css',
        'css!js/ext/DataTables/ColReorder-1.5.0/css/colReorder.dataTables.min.css',
        'css!js/ext/DataTables/FixedColumns-3.2.5/css/fixedColumns.dataTables.min.css',
        'css!js/ext/DataTables/FixedHeader-3.1.4/css/fixedHeader.dataTables.min.css',
        'css!js/ext/DataTables/KeyTable-2.5.0/css/keyTable.dataTables.min.css',
        'css!js/ext/DataTables/Responsive-2.2.2/css/responsive.dataTables.min.css',
        'css!js/ext/DataTables/RowGroup-1.1.0/css/rowGroup.dataTables.min.css',
        'css!js/ext/DataTables/RowReorder-1.2.4/css/rowReorder.dataTables.min.css',
        'css!js/ext/DataTables/Scroller-2.0.0/css/scroller.dataTables.min.css',
        'css!js/ext/DataTables/Select-1.3.0/css/select.dataTables.min.css',

        'datatables.net',
        'datatables.net-autofill',
        'datatables.net-buttons',
        'datatables.net-buttons/colVis',
        'datatables.net-buttons/print',
        'datatables.net-buttons/html5',
        'datatables.net-colreorder',
        'datatables.net-fixedcolumns',
        'datatables.net-fixedheader',
        'datatables.net-keytable',
        'datatables.net-responsive',
        'datatables.net-rowgroup',
        'datatables.net-rowreorder',
        'datatables.net-scroller',
        'datatables.net-select'
], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, require, $log) {
        var c = this;

        c.settingsDefinition = {
            dtdata: { isLive: true, isRequired: false, defaultValue: null },
            dtcolumns: { isLive: true, isRequired: false, defaultValue: [] },
            dtoptions: { isLive: false, isRequired: false, defaultValue: {} },
            tableRef: { isLive: true }
        };

        c.validateValues = function (settings) {
            // checking for direct content
            if (settings.__hasDirectContent) {
                var msg = "fusion-datatable does not support direct content";
                $log.warning(msg);
            }
        };

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        };

        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

            var defaultOptions = {
                responsive: true
            };

            var options = $.extend({}, defaultOptions, ko.unwrap(settings.dtoptions));

            // overwriting the extended options w the items specified directly in the settings
            if (ko.unwrap(settings.dtdata)) {
                options.data = ko.unwrap(settings.dtdata);
                options.columns = ko.unwrap(settings.dtcolumns);


                // if dtdata is specified, then control handles the data change subscription
                if (ko.isObservableArray(settings.dtdata)) {
                    settings.dtdata.subscribe(function (changes) {
                        changes.forEach(function (change) {

                            // handling added items
                            if (change.status === "added") {
                                ko.unwrap(settings.tableRef).DataTable().row.add(change.value).draw();
                            }
                                // handling deleted items
                            else if (change.status === "deleted") {
                                var rowidx = ko.unwrap(settings.tableRef).DataTable().column(0).data().indexOf(change.value.id);
                                ko.unwrap(settings.tableRef).DataTable().row(rowidx).remove().draw();
                            }
                        });

                    }, null, "arrayChange");
                }

            }


            // creating the data table
            var $table = $markup.find('table');
            $table.DataTable(options);


            // exposing the data table back to the VM
            settings.tableRef($table);



            //// adding a selected row indicator
            //$table.on('click', 'tr', function () {
            //    if ($(this).hasClass('selected')) {
            //        $(this).removeClass('selected');
            //    }
            //    else {
            //        //$table.$('tr.selected').removeClass('selected');
            //        $table.find("tr.selected").removeClass('selected');
            //        $(this).addClass('selected');
            //    }
            //});

         

        };
    });
});
